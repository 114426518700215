import DropDownMenu_SeparateBasicMenuButtonSkinComponent from '../components/DropDownMenu/viewer/skinComps/SeparateBasicMenuButtonSkin/SeparateBasicMenuButtonSkin.skin';
import DropDownMenu_SeparateBasicMenuButtonSkinController from '../components/DropDownMenu/viewer/DropDownMenu.controller';


const DropDownMenu_SeparateBasicMenuButtonSkin = {
  component: DropDownMenu_SeparateBasicMenuButtonSkinComponent,
  controller: DropDownMenu_SeparateBasicMenuButtonSkinController
};


export const components = {
  ['DropDownMenu_SeparateBasicMenuButtonSkin']: DropDownMenu_SeparateBasicMenuButtonSkin
};

